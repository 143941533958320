import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


function SecurityGuards() {
  const [state, setState] = useState(false);
  const navigate=useNavigate()
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [state]);
  return (
    <div>
      <div
        class="breadcrumb-area bg-image section-ptb"
        style={{
          background:
            "url(assets/images/bg/cctv-security-technology-with-lock-icon-digital-remix.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginTop: "5rem",
        }}
      >
        <div class="container">
          <div class="row breadcrumb">
            <div class="col" style={{ marginTop: "4rem" }}>
              <h2>SECURITY GUARDS</h2>

              <ul class="breadcrumb-list">
                <li class="breadcrumb-item">
                  <a onClick={()=>{navigate('/')}}>Home</a>
                </li>
                <li class="breadcrumb-item active">Service</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <main class="page-content" data-aos="fade-right">
        <div class="section-service bg-gray section-pt section-pb-90">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-title text-center">
                  <h2 style={{ textAlign: "center" }}>
                    Security Guards Services
                  </h2>
                </div>
                <p
                  style={{
                    fontSize: "large",
                    textAlign: "justify",
                    marginBottom: "2rem",
                  }}
                >
                  Green corps offers integrated services in industrial security,
                  fire fighting and rescue, industrial safety and damage
                  control, investigation and detection, while also lending
                  consulting services in areas like security, safety, industrial
                  relations and labour laws. The company provides electronic
                  equipment for security, safety and fire prevention. In short,
                  when it comes to guarding your valuable assets, there is one
                  entity that has all that you need to ensure absolute peace of
                  mind.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-12" data-aos="flip-up">
                <div class="service text-center service-2 padding-none cleaning-service mb-30">
                  <div class="thumb">
                    <img
                      src="assets/images/serviece/security-1.jpg"
                      alt="service img"
                    />
                  </div>
                  <div class="content">
                    <h4>Ex-Servicemen Guard</h4>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      Get the best of all security for your place from an
                      experienced serviceman who has served the country.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12" data-aos="flip-up">
                <div class="service text-center service-2 padding-none cleaning-service  mb-30">
                  <div class="thumb">
                    <img
                      src="assets/images/serviece/security-2.jpg"
                      alt="service img"
                    />
                  </div>
                  <div class="content">
                    <h4>Security Executives</h4>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      Feel secure with our security executives. Having faster
                      decision-making sense makes them different. 
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12" data-aos="flip-up">
                <div class="service text-center service-2 padding-none cleaning-service  mb-30">
                  <div class="thumb">
                    <img
                      src="assets/images/serviece/security-3.jpg"
                      alt="service img"
                    />
                  </div>
                  <div class="content">
                    <h4>Lady Security Executives</h4>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      For females, we have lady security executives who are
                      equally strong and intelligent. 
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12" data-aos="flip-up">
                <div class="service text-center service-2 padding-none cleaning-service  mb-30">
                  <div class="thumb">
                    <img
                      src="assets/images/serviece/security-4.jpg"
                      alt="service img"
                    />
                  </div>
                  <div class="content">
                    <h4>Armed security Executives</h4>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      They are the best at the job, with the knowledge to
                      operate the weapon and the best decision-making power
                      during a crisis.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12" data-aos="flip-up">
                <div class="service text-center service-2 padding-none cleaning-service  mb-30">
                  <div class="thumb">
                    <img
                      src="assets/images/serviece/security-5.jpg"
                      alt="service img"
                    />
                  </div>
                  <div class="content">
                    <h4>Body Guards/ Bouncers/ Close protection officers</h4>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      For better security, they are the best people because of
                      their great consciousness and strong physic.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12" data-aos="flip-up">
                <div class="service text-center service-2 padding-none cleaning-service  mb-30">
                  <div class="thumb">
                    <img
                      src="assets/images/serviece/high-angle-guard-man-protecting-client.jpg"
                      alt="service img"
                    />
                  </div>
                  <div class="content">
                    <h4>Escort And Pilot Duties</h4>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      Make your journey more protected with the escort and
                      pilots. Smart profiles and great skills make them the
                      best.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12" data-aos="flip-up">
                <div class="service text-center service-2 padding-none cleaning-service  mb-30">
                  <div class="thumb">
                    <img
                      src="assets/images/serviece/Event-Management.jpg"
                      alt="service img"
                    />
                  </div>
                  <div class="content">
                    <h4>Consultancy Services</h4>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      In every path of life you need consultation and we are
                      here for that. Help you get the best suggestions.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12" data-aos="flip-up">
                <div class="service text-center service-2 padding-none cleaning-service  mb-30">
                  <div class="thumb">
                    <img
                      src="assets/images/serviece/airport-security-officer-using-metal-detector-check-bag.jpg"
                      alt="service img"
                    />
                  </div>
                  <div class="content">
                    <h4>Event Management / Celebrity Program</h4>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      A celebrity event or any other public event we make the
                      best for you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SecurityGuards;
